<template>
	<div class="modal" style="display:block">

		<div class="modal-content" v-bind:class="{ 'bravo' : hasError === false}">

			<!-- question -->
			<img v-if="hasError === null && showEcranFin === null && current.image_titre !== undefined" :src="current.image_titre" width="100">
			<p class="blue" v-if="hasError === null && showEcranFin === null" v-html="current.libelle">{{ current.libelle }}>{{ current.libelle }}</p>

			<div v-if="hasError === true">
				<p><strong><big class="red">PAS TOUT A FAIT !</big></strong> <br>
				<span v-html="current.messages.erreur"></span></p>
			</div>
			<div v-if="hasError === false && showEcranFin === null">
				<h2>BRAVO !</h2>
				<p><span v-html="current.messages.bravo"></span></p>
			</div>

			<!-- écrans de début avec des images avant de débuter le quiz -->
			<div v-if="showEcranDebut !== null">
				<template v-for="ecranDebut, index in current.ecransDebut">
					<template v-if="showEcranDebut === index">
						<p v-html="ecranDebut.titre" :key="'p'+index"></p>
						<div :key="index" :class="(ecranDebut.class !== undefined) ? ecranDebut.class : 'visuel'">
							<!-- <p class="blue" v-html="ecranDebut.titre"></p> -->
							<img :src="ecranDebut.image"
							:alt="ecranDebut.alt">
						</div>
					</template>
				</template>
			</div>

			<!-- écrans de fin avec des images après un quiz, avant de passer à la suite -->
			<template v-if="showEcranFin !== null">
				<template v-for="ecranFin, index in current.ecransFin">
					<template v-if="showEcranFin === index">
						<!-- <p v-html="ecranFin.titre" :key="'p'+index"></p> -->
						<!-- <div class="visuel" :key="index"> -->
						<div :key="index" :class="(ecranFin.class !== undefined) ? ecranFin.class : 'visuel'">
							<p class="blue" v-html="ecranFin.titre"></p>
							<img :src="ecranFin.image"
								:alt="ecranFin.alt">
						</div>
					</template>
				</template>
			</template>

			<!-- réponses -->
			<!-- Question avec un visuel d'intro + réponses -->
			<template v-if="showEcranFin === null && showEcranDebut === null">

				<div v-if="current.class === 'ibd'" class="ibd">
					<img
						:src="current.visuel.image"
						:alt="current.visuel.alt">
					<ul class="options">
						<li v-for="(reponse, index) in current.reponses" :key="index">
							<button type="button" class="btn defaut"
							:id="index"
							:disabled="hasError === false"
							@click="$event.target.classList.toggle('defaut');$event.target.classList.toggle('selected');">
								{{ reponse.libelle }}
							</button>
						</li>
					</ul>
				</div>

				<!-- autres QCM -->
				<ul v-else class="options" :class="current.class">
					<li v-for="(reponse, index) in current.reponses" :key="index">
					<button v-if="reponse.libelle" type="button" class="btn defaut"
						:id="index"
						:disabled="hasError === false"
						@click="$event.target.classList.toggle('defaut');$event.target.classList.toggle('selected');">
						{{ reponse.libelle }}
					</button>
					<img v-else
							:src="reponse.image"
							:alt="reponse.alt">
					</li>
				</ul>

			</template>

			<p v-if="hasError === false" class="legende" v-html="current.messages.legende"></p>
		</div>

		<!-- MODAL FOOTER -->
		<div class="modal-footer">

			<button v-if="hasError === null && (showEcranDebut !== null && showEcranDebut <= current.ecransDebut.length -1)" type="button" class="btn btn-blue align-right" @click="afficheEcransDebut()"><span>Suivant</span></button>
			<!-- BTN VALIDER -->
			<button v-if="hasError !== false && showEcranDebut === null" type="button" class="btn btn-blue align-center" @click="checkSelectedReponses()"><span>Valider</span></button>

			<!-- BTN SUIVANT - AFFICHER UNE FOIS QUE LE USER A VALIDE CETTE ETAPE POUR PASSER A LA PROCHAINE -->

			<!-- s'il y a des ecrans de fin à afficher -->
			<button v-if="hasError === false && (current.ecransFin !== undefined && (showEcranFin === null || showEcranFin < current.ecransFin.length -1))" type="button" class="btn btn-blue align-right" @click="afficheEcransFin()"><span>Suivant</span></button>

			<button v-if="hasError === false && current.ajoutDossier !== undefined && !isAlreadyInDossier && showEcranFin === current.ecransFin.length -1" type="button" class="btn btn-blue align-center" @click="addToDossier()"><span>Ajouter au dossier médical</span></button>

			<!-- passer au quiz suivant ou close modal -->
			<button v-if="hasError === false && (current.ecransFin === undefined || showEcranFin === current.ecransFin.length -1) && (current.ajoutDossier === undefined || isAlreadyInDossier)" type="button" class="btn btn-blue align-right" @click="ecranSuivant()"><span>Suivant</span></button>
		</div>
	</div>
</template>


<script>
import jsonQuiz from '@/data/quiz.json'
import { EventBus } from '@/core/event-bus.js';
import { GAME_EVENT } from '@/core/gameConfig'

export default {
	name: 'QuizQCM',
	data() {
		return {
			quizList: jsonQuiz,
			currentQuestion: 1,
			hasError: null,
			nbBonnesReponsesSelected: 0,
			showEcranDebut: null,
			showEcranFin: null,
			isAlreadyInDossier: false
		}
	},
	props : ['options'],
	mounted() {
		if (this.options && this.options.currentQuestion) {
			this.currentQuestion = this.options.currentQuestion
		}

		if (this.current.ecransDebut !== undefined) {
			this.showEcranDebut = 0
		}
	},
	computed: {
		current() {
			return this.quizList[this.currentQuestion]
		},
		bonnesReponsesIds() {
			let bonnes_reponses = [];

			for (let i = 0; i < this.current.reponses.length; i++) {
				if (this.current.reponses[i].bonne_reponse) {
				bonnes_reponses.push(parseInt(i,10));
				}
			}

			return bonnes_reponses
		}
	},
	methods : {
		close() {
			this.$emit('close')
		},

		checkSelectedReponses() {
			this.hasError = null

			// on parcourt les reponses selectionnees
			let selected = document.getElementsByClassName('selected')
			for (let j=(selected.length - 1); j>=0; j--) {

				// index de la reponse
				let rep_id = parseInt(selected[j].getAttribute('id'),10)

				// si la réponse sélectionnée est parmi les bonnes reponses
				if ((this.bonnesReponsesIds).indexOf(rep_id) !== -1) {
					selected[j].classList.add('right')
					selected[j].classList.remove('selected')
					this.nbBonnesReponsesSelected++
				} else {
					selected[j].classList.add('wrong')
					selected[j].classList.remove('selected')
				}
			}

			// s'il manque des bonnes réponses
			if (this.nbBonnesReponsesSelected != this.bonnesReponsesIds.length) {
				this.hasError = true
			} else {

				// toutes les bonnes réponses OK
				this.hasError = false

				// on reset la couleur des mauvaises reponses pour ne laisser que les bonnes reponses en vert
				let mauvaises_reponses = document.getElementsByClassName('wrong')
				for (let j=(mauvaises_reponses.length - 1); j>=0; j--) {
					mauvaises_reponses[j].classList.add('defaut')
					mauvaises_reponses[j].classList.remove('wrong')
				}
			}
		},

		afficheEcransDebut() {
			if (this.showEcranDebut === null) {
				this.showEcranDebut = 0
			} else {
				this.showEcranDebut++
			}

			if (this.showEcranDebut == this.current.ecransDebut.length) {
				this.showEcranDebut = null
			}
		},

		afficheEcransFin() {
			if (this.showEcranFin === null) {
				this.showEcranFin = 0
			} else {
				this.showEcranFin++
			}
		},

		addToDossier() {
			this.$store.commit("addToDossier", {dossier: this.current.ajoutDossier.dossier, item: this.current.ajoutDossier.item})
			this.isAlreadyInDossier = true

			// fix pour pouvoir laisser le quiz declenché auto de se lancer si c'est aussi un QCM
			// on ferme pour que ça puisse se rouvrir auto
			// ça ne pose pas de probleme de fermer vu que l'ajout au dossier se fait à la toute fin du quiz
			// > sauf si on doit afficher une directive après un ajout au dossier
			if (this.current.affichageDirective == undefined) {
				this.$emit('close')
			}
		},

		ecranSuivant() {
			if (this.current.enchaineQuizIndex != undefined) {
				let nextQuiz = this.quizList[this.current.enchaineQuizIndex]

				if (nextQuiz.type !== this.current.type) {
					EventBus.$emit('changeQuiz', nextQuiz.type, nextQuiz.index);
				} else {

					// on reset les bonnes reponses avant de passer au quiz suivant
					let bonnes_reponses = document.getElementsByClassName('right')
					for (let j=(bonnes_reponses.length - 1); j>=0; j--) {
						bonnes_reponses[j].classList.add('defaut')
						bonnes_reponses[j].classList.remove('right')
					}
					this.nbBonnesReponsesSelected = 0
					this.hasError = null
					this.showEcranFin = null

					this.currentQuestion = this.current.enchaineQuizIndex
				}

			} else {

				this.close()

				if (this.current.affichageDirective !== undefined) {
					EventBus.$emit(GAME_EVENT.SHOWDIRECTIVE, this.current.affichageDirective);

					// FIN DU JEU - ON ARRETE LE CHRONO
					if (this.current.affichageDirective == 9) {
						EventBus.$emit(GAME_EVENT.PAUSECHRONO);
					}
				}
			}
		}
	}
}
</script>


<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .fullscreen {
        width: calc(100% + 11rem);
        margin-left: -50%;
        left: calc(50% - 5.5rem);
        position: relative;
        // top: -6rem;
		top: -7.5rem;

        img {
            max-width: 100%;
        }
    }

	.modal-content {
		&.bravo {
			.visuel {
				img {
					max-width: 70%;
				}
			}
		}
	}
</style>